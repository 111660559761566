'use strict';

//function error(title, message){
//    $(".alert-warning strong").html(title);
//    $(".alert-warning span").html(message);
//    $(".alert-warning").fadeIn(300).delay(6000).fadeOut(300)
//}

var Graphic = React.createClass({
    getInitialState: function() {
        return {
            mouse: {x: 0, y: 0},
            maxMouse: {x: 0, y: 0},
            minMouse: {x: 100000, y: 100000},
            tracking: [],
            hidden: false,
            drawFinish: false,
            canvas: {width: 1000, height: 750}
        }
    },
    clearTrack:function(tracking, scale_x, scale_y){

        var _tracking = [];
        var pre_step;
        var lossy = 4;
        for(var i = 0; i < tracking.length; i++){
            var step = tracking[i];
            step.x = parseInt(step.x * scale_x * 100)/100;
            step.y = parseInt(step.y * scale_y * 100)/100;
            if(pre_step) {
                if(Math.abs(pre_step.x-step.x) > lossy||Math.abs(pre_step.y-step.y) > lossy || i == tracking.length-1 || i == 0){
                    pre_step = step;
                    _tracking.push(step);
                }
            }else{
                pre_step = step;
                _tracking.push(step);
            }

        }
        return _tracking;
    },
    componentDidMount: function() {


        var canvas = ReactDOM.findDOMNode(this);
        var context = canvas.getContext('2d');
        var canvas_style = getComputedStyle(canvas);

        canvas.width = parseInt(canvas_style.getPropertyValue('width'));
        canvas.height = parseInt(canvas_style.getPropertyValue('height'));

        context.lineWidth = 3;
        context.lineJoin = 'round';
        context.lineCap = 'round';
        context.strokeStyle = 'rgba(241, 196, 15, 1)';
        this.setState({canvas: canvas, context: context});
        if(this.props.data && this.props.data.tracking && !this.props.data.drawFinish){
            var scale_x = 1;
            var scale_y = 1;

            if(this.props.data.canvas&& this.props.data.canvas.width){
                scale_x = canvas.width/this.props.data.canvas.width;
                scale_y = canvas.height/this.props.data.canvas.height;
            }
            var tracking = this.clearTrack(this.props.data.tracking, scale_x, scale_y);

            this.setState({drawFinish: true, tracking: tracking, delay: this.props.data.delay});

            if(this.props.data.animate){
                this.drawPoint(context, tracking[0], true);
                this.drawTrackAnimate(0);
            }else{
                this.drawPoint(context, tracking[0], true);
                for(var i = 1; i < tracking.length-2; i++){
                    this.drawPoint(context, tracking[i]);
                }
                this.drawPoint(context, tracking[tracking.length-1], false);
            }
        }


    },
    drawTrackAnimate:function(n){
        if(n < this.state.tracking.length || this.state.tracking.length == 0){
            var _this = this;
            setTimeout(function(){
                if(_this.state.context && _this.state.tracking) {
                    _this.drawPoint(_this.state.context, _this.state.tracking[n]);
                    _this.drawTrackAnimate(n + 1);
                }
            }, 2*(_this.state.delay && n == 1 ? _this.state.delay : 1));
        }

    },
    drawPoint: function(context, point, position){
        if(position == true){
            context.beginPath();
            context.moveTo(point.x, point.y);
        }else{
            context.lineTo(point.x, point.y);
        }
        context.stroke();
    },
    componentDidUpdate: function() {

        //this.state.context.clearRect(0, 0, 200, 200);

    },
    onMouseMove: function(e){

        e = e.targetTouches ? e.targetTouches[0] : e;
        var offset = this.state.canvas.getBoundingClientRect();
        this.state.mouse.x = e.pageX - offset.left;
        this.state.mouse.y = e.pageY - (offset.top + window.pageYOffset) + (iOS ? 0 : 14);

    },
    onMouseDown: function(e) {
        if(this.state.drawFinish) return;
        if(this.state.tracking.length == 0 && !e.targetTouches) {
            if(this.state.mouse.x && this.state.mouse.y) {
                this.drawPoint(this.state.context, {x: this.state.mouse.x, y: this.state.mouse.y}, true);
            }
            e.target.addEventListener('mousemove', this.onPaint, false);

            if(!this.state.mouse.x || !this.state.mouse.y) return;
            this.state.minMouse.x = this.state.mouse.x;
            this.state.minMouse.y = this.state.mouse.y;
            this.state.maxMouse.x = this.state.mouse.x;
            this.state.maxMouse.y = this.state.mouse.y;
        }
        var _this = this;
        $(e.target).bind("touchmove",function(event){
            _this.onMouseMove(event.originalEvent);
            _this.onPaint();
            event.preventDefault();

        });
        $(e.target).bind("touchend",function(event){
            _this.onMouseUp(event.originalEvent);
            $(e.target).unbind("touchmove touchend");
            $(e.target).bind("touchend");
        });
    },
    onMouseUp: function(e) {

        if(this.state.drawFinish) return;
        e.target.removeEventListener('mousemove', this.onPaint, false);
        this.state.drawFinish = true;
        if(!this.state.mouse.x || !this.state.mouse.y || !this.state.mouse.x || !this.state.mouse.y) return;
        this.drawPoint(this.state.context, {x: this.state.mouse.x, y: this.state.mouse.y}, false);
        //e = e.targetTouches ? e.targetTouches[0] : e;
        console.log(e, this.state);
        this.props.onDrawFinish(e, this.state);

    },
    onPaint: function(){
        if(!this.state.mouse.x || !this.state.mouse.y || !this.state.mouse.x || !this.state.mouse.y) return;
        this.state.maxMouse.x = Math.max(this.state.maxMouse.x, this.state.mouse.x);
        this.state.maxMouse.y = Math.max(this.state.maxMouse.y, this.state.mouse.y);
        this.state.minMouse.x = Math.min(this.state.minMouse.x, this.state.mouse.x);
        this.state.minMouse.y = Math.min(this.state.minMouse.y, this.state.mouse.y);

        this.state.tracking.push({x: this.state.mouse.x, y: this.state.mouse.y});
        this.drawPoint(this.state.context, {x: this.state.mouse.x, y: this.state.mouse.y});



    },
    render: function() {

        var visible_class = (this.props.data && this.props.data.hidden) ? " hidden_canvas" : " visible";
        visible_class += " canvasInAPerfectWorld";
        return <canvas className={visible_class} onMouseDown={this.onMouseDown}  onTouchStart={this.onMouseDown} onMouseUp={this.onMouseUp} onMouseMove={this.onMouseMove}/>;

    }

});

var FindWordGame = React.createClass({
    getInitialState: function() {
        return {
            correct_count: 0,
            drawings: [{id: Math.random()}],
            letters_greed: "EBAYJTDJBALTIKA" +
            "               XSSDFCOCACOLATY" +
            "               YUAIOGJHCINZANO" +
            "               ERMTMEGAFONUIOP" +
            "               WHSNAMQVICHYSLS" +
            "               VEUBDCFSZAKOMM7" +
            "               CNNXITOYOTAHAAF" +
            "               DKGADVITANAJRCG" +
            "               AEFGAHJKLZBKSDP" +
            "               NLDSSAPOIUMKLOR" +
            "               NSKITTLESYWFZNO" +
            "               OTALBERTBVCCXAC" +
            "               NSTAROPRAMENALT" +
            "               QWEVOLKSWAGENDE" +
            "               MPEPSICONMINISR",
            logotypes_array: [
                {logo:"0_coca_cola.svg", name:"cocacola",
                    location:{x: 5, y: 1, verical: false}, isCorrect: false},
                {logo:"1_pepsi.svg", name:"pepsico",
                    location:{x: 1, y: 14, verical: false}, isCorrect: false},
                {logo:"2_macdonalds.svg", name:"macdonalds",
                    location:{x: 13, y: 5, verical: true}, isCorrect: false},
                {logo:"3_kfc.svg", name:"kfc",
                    location:{x: 11, y: 9, verical: true}, isCorrect: false},
                {logo:"4_adidas.svg", name:"adidas",
                    location:{x: 4, y: 4, verical: true}, isCorrect: false},
                {logo:"5_bmw.svg", name:"bmw",
                    location:{x: 10, y: 8, verical: true}, isCorrect: false},
                {logo:"6_mini.svg", name:"mini",
                    location:{x: 9, y:14, verical: false}, isCorrect: false},
                {logo:"7_VW.svg", name:"volkswagen",
                    location:{x: 3, y: 13, verical: false}, isCorrect: false},
                {logo:"8_toyota.svg", name:"toyota",
                    location:{x: 5, y: 6, verical: false}, isCorrect: false},
                {logo:"9_PG.svg", name:"procter",
                    location:{x: 14, y: 8, verical: true}, isCorrect: false},
                {logo:"10_s7.svg", name:"s7",
                    location:{x: 14, y:4, verical: true}, isCorrect: false},
                {logo:"11_henkel.svg", name:"henkel",
                    location:{x: 1, y: 4, verical: true}, isCorrect: false},
                {logo:"12_staropramen.svg", name:"staropramen",
                    location:{x: 1, y: 12, verical: false}, isCorrect: false},
                {logo:"13_megafon.svg", name:"megafon",
                    location:{x: 4, y: 3, verical: false}, isCorrect: false},
                {logo:"14_samsung.svg", name:"samsung",
                    location:{x: 2, y: 1, verical: true}, isCorrect: false},
                {logo:"15_baltika.svg", name:"baltika",
                    location:{x:8, y:0, verical:  false}},
                {logo:"16_ebay.svg", name:"ebay",
                    location:{x: 0, y:0, verical: false}, isCorrect: false},
                {logo:"17_albert.svg", name:"albert",
                    location:{x: 2, y: 11, verical: false}, isCorrect: false},
                {logo:"18_vitana.svg", name:"vitana",
                    location:{x: 5, y: 7, verical: false}, isCorrect: false},
                {logo:"19_vichy.svg", name:"vichy",
                    location:{x: 7, y: 4, verical: false}, isCorrect: false},
                {logo:"20_chinzano.svg", name:"cinzano",
                    location:{x: 8, y: 2, verical: false}, isCorrect: false},
                {logo:"21_danon.svg", name:"dannon",
                    location:{x: 0, y: 7, verical: true}, isCorrect: false},
                {logo:"22_skittles.svg", name:"skittles",
                    location:{x: 1, y: 10, verical: false}, isCorrect: false},
                {logo:"23_mars.svg", name:"mars",
                    location:{x: 12, y: 5, verical: true}, isCorrect: false}]
        };
    },
    onDrawFinish:function(canvas, object) {

        this.setState({drawings:this.state.drawings});

        var w_cell = $("#our_clients_table").width()/15;
        var x_min = (object.minMouse.x - w_cell -($("#our_clients_table > ul > canvas").width()-$("#our_clients_table > ul").width())/2)/w_cell;
        var x_max = (object.maxMouse.x + w_cell - ($("#our_clients_table > ul > canvas").width()-$("#our_clients_table > ul").width())/2)/w_cell-1;
        var y_min = (object.minMouse.y - w_cell - ($("#our_clients_table > ul > canvas").height()-$("#our_clients_table > ul").height())/2)/w_cell;
        var y_max = (object.maxMouse.y + w_cell - ($("#our_clients_table > ul > canvas").height()-$("#our_clients_table > ul").height())/2)/w_cell-1;


        var cells = [];

        $("#our_clients_table > ul li").removeClass("hovered");
        for(var i = 0; i < this.state.letters_greed.length; i++){
            if(!this.state.letters_greed.isCorrect && this.state.letters_greed[i].x > x_min && this.state.letters_greed[i].x < x_max && this.state.letters_greed[i].y > y_min && this.state.letters_greed[i].y < y_max) {
                cells.push(this.state.letters_greed[i]);
                $($("#our_clients_table > ul li")[i]).addClass("hovered");
            }
        }


        var filtred_brands = [];
        var logo_cells;
        for(var i = 0; i < this.state.logotypes_array.length; i++){
            var filtred_cells = [];
            var logo = this.state.logotypes_array[i];
            if(!logo.isCorrect) {
                if (logo.location.verical) {

                    for (var ii = 0; ii < cells.length; ii++) {

                        if (cells[ii].x == logo.location.x && cells[ii].y >= logo.location.y && cells[ii].y < logo.location.y + logo.name.length) {
                            filtred_cells.push(cells[ii]);
                            //debugger;
                        }
                    }
                } else {
                    for (var ii = 0; ii < cells.length; ii++) {

                        if (cells[ii].y == logo.location.y && cells[ii].x >= logo.location.x && cells[ii].x < logo.location.x + logo.name.length) {
                            filtred_cells.push(cells[ii]);
                        }
                    }
                }

                if(filtred_cells.length == logo.name.length){
                    logo_cells = filtred_cells;

                    filtred_brands.push(logo);
                }
            }
        }
        object.hidden = true;
        if(filtred_brands.length == 1){
            filtred_brands = filtred_brands[0];
            this.state.drawings[this.state.drawings.length-1].isCorrect = true;
            for (var ii = 0; ii < logo_cells.length; ii++) {
                logo_cells[ii].isCorrect = true;
                logo_cells[ii].vertical = filtred_brands.location.verical;
                if(logo_cells[ii].x == filtred_brands.location.x && logo_cells[ii].y == filtred_brands.location.y){
                    logo_cells[ii].first = true;
                }

                if(filtred_brands.location.verical){
                    if(logo_cells[ii].y == filtred_brands.location.y + filtred_brands.name.length-1){
                        logo_cells[ii].last = true;
                    }
                }else{
                    if(logo_cells[ii].y == filtred_brands.location.y && logo_cells[ii].x == filtred_brands.location.x+filtred_brands.name.length-1){
                        logo_cells[ii].last = true;
                    }

                }
            }
            filtred_brands.isCorrect = true;
            this.state.correct_count++;
        }
        this.state.drawings[this.state.drawings.length-1] = object;

        this.state.drawings.push({id: Math.random()});

        this.setState({correct_count: this.state.correct_count, letters_greed: this.state.letters_greed, drawings: this.state.drawings, logotypes_array: this.state.logotypes_array});

    },
    componentWillMount: function() {
        var letters_greed = this.state.letters_greed.split(" ").join("");
        var letters_greed_cells = [];
        for (var i = 0; i < letters_greed.length; i++) {
            letters_greed_cells.push({
                letter:letters_greed[i],
                num: i,
                x: i%15,
                y: Math.floor(i/15)
            });
        }
        this.setState({letters_greed:letters_greed_cells})
    },
    markBrandAsCorrect: function(brand_id, delay){

        var _this = this;
        setTimeout(function() {


            var letters_greed = _this.state.letters_greed;
            var correct_count = _this.state.correct_count;
            var logo = _this.state.logotypes_array[brand_id];
            if(!logo.isCorrect) correct_count++;
            logo.isCorrect = true;
            if (logo.location.verical) {

                for (var ii = 0; ii < letters_greed.length; ii++) {
                    if (letters_greed[ii].x == logo.location.x && (letters_greed[ii].y >= logo.location.y && letters_greed[ii].y <= (logo.location.y + logo.name.length-1))) {
                        letters_greed[ii].isCorrect = true;
                        letters_greed[ii].vertical = true;
                        if(logo.location.y == letters_greed[ii].y){
                            letters_greed[ii].first = true;
                        }else if(letters_greed[ii].y == logo.location.y + logo.name.length-1){
                            letters_greed[ii].last = true;
                        }else{
                            letters_greed[ii].middle = true;
                        }
                    }
                }
            } else {
                for (var ii = 0; ii < letters_greed.length; ii++) {
                    if (letters_greed[ii].y == logo.location.y && (letters_greed[ii].x >= logo.location.x && letters_greed[ii].x <= (logo.location.x + logo.name.length-1))) {
                        letters_greed[ii].isCorrect = true;
                        letters_greed[ii].vertical = null;
                        if(logo.location.x == letters_greed[ii].x){
                            letters_greed[ii].first = true;
                        }else if(letters_greed[ii].x == logo.location.x + logo.name.length-1){
                            letters_greed[ii].last = true;
                        }else{
                            letters_greed[ii].middle = true;
                        }
                    }
                }
            }
            _this.setState({correct_count: correct_count, logotypes_array: _this.state.logotypes_array, letters_greed: letters_greed});
        }, delay);
    },
    skipGame: function(){
        this.setState({solved: true});
        for(var i = 0; i< this.state.logotypes_array.length; i++){
            this.markBrandAsCorrect(i, i*100);
        }
    },
    render:function() {
        var logotypes_greed = [];

        for (var i = 0; i < this.state.logotypes_array.length; i++) {
            var className = "";
            if(this.state.logotypes_array[i].isCorrect){
                className += " correct";
            }
            var img = "img/our_clients/"+this.state.logotypes_array[i].logo;
            logotypes_greed.push(<li key={i} className={className}><img src={img}/></li>);
        }
        var alphabet_greed = [];

        for (var i = 0; i < this.state.letters_greed.length; i++) {
            var className = "";
            var span_class = "";

            if(this.state.letters_greed[i].isCorrect == true){
                className += " correct";
                if(this.state.letters_greed[i].first || this.state.letters_greed[i].vertical){
                    className += " border_left";
                }
                if(this.state.letters_greed[i].last || this.state.letters_greed[i].vertical){
                    className += " border_right";
                }
                if(this.state.letters_greed[i].first  ||! this.state.letters_greed[i].vertical){
                    className += " border_top";
                }
                if(this.state.letters_greed[i].last  ||! this.state.letters_greed[i].vertical){
                    className += " border_bottom";
                }
                if(this.state.letters_greed[i].first){
                    span_class += " first";
                    className += " first";
                }else if(this.state.letters_greed[i].last){
                    span_class += " last";
                    className += " last";
                }else{
                    span_class += " middle";
                    className += " middle";
                }
                if(this.state.letters_greed[i].vertical){
                    span_class += " vertical";
                    className += " vertical";
                }else{
                    span_class += " horizontal";
                    className += " horizontal";
                }

            }

            alphabet_greed.push(<li className={className} key={i}><span><span className={span_class}>{this.state.letters_greed[i].letter}</span></span></li>);
        }

        var drawings = [];

        for (var i = 0; i < this.state.drawings.length; i++) {
            var className = "";
            if(this.state.drawings[i].isCorrect){
                className += " correct";
            }
            drawings.push(<Graphic className={className} key={i} data={this.state.drawings[i]} onDrawFinish={this.onDrawFinish}/>);
        }

        return (
            <div id="FindWordGame">
                <div className="left">
                    <div id="FindWord_description">
                        <p dangerouslySetInnerHTML={{__html: lang.our_clients.p}}></p>
                    </div>
                    <ul id="our_clients_logos">
                        {logotypes_greed}
                    </ul>
                </div>
                <div className="right noselect" id="our_clients_table">
                        <ul>
                            {drawings}
                            {alphabet_greed}
                        </ul>
                        <div id="our_clients_score">
                            <span>{this.state.correct_count}/{this.state.logotypes_array.length}</span>
                            <b onClick={this.skipGame} disabled={this.state.solved} className="skip_game">{lang.skip_game}</b>
                        </div>
                </div>
            </div>
        )}
});